export default {
  mounted: function () {
    this.bindOrder();
  },

  beforeDestroy: function () {
    this.unbindOrder();
  },

  methods: {
    async bindOrder() {
      await this.$store.dispatch("deliveryCart/bind", this.$route.query);
    },
    unbindOrder() {
      this.$store.dispatch("deliveryCart/unbind", this.$route.query);
    },
  },
};
