<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";
import firebaseDeliveryBindOrder from "@/mixins/firebase/bindDeliveryOrder";

export default {
  name: "WebordersPrecart",

  metaInfo: { title: "Mi Pedido" },

  extends: View,

  mixins: [
    firebaseDeliveryBindOrder,
    LoadSections([
      "layout-header-menu-with-return-button-and-title",
      "public-delivery-precart",
      "layout-footer-menu-product-selection-extras",
    ]),
  ],
};
</script>